import { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router';
import ReactGA from 'react-ga4';
import { Guesses } from '../../components/Guesses/Guesses';
import { Keyboard } from '../../components/Keyboard/Keyboard';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { GameLength } from '../../config/config';
import { checkGuessValid } from '../../functions/checkGuessValid';
import { gameState } from '../../state/GameState';
import { randomIndex } from '../../functions/randomElement';
import { Dle } from '../../models/dles';
import './GameView.css';

function GameView() {
  const { lenStr, idStr } = useParams();
  const len = Number(lenStr) as GameLength;
  const id = Number(idStr);

  const [game, setGame] = useState<Dle | null>();
  const [currentGuess, setCurrentGuess] = useState<string>('');
  const [currentGuessInvalid, setCurrentGuessInvalid] = useState(false);

  useEffect(() => {
    const setViewGame = async () => {
      if (idStr === 'random') {
        const solutions = await gameState.getSolutions(len);
        const gameStatuses = await gameState.getGameStatuses(len);
        const randomGame = await gameState.getGame(
          len,
          randomIndex(
            solutions.length,
            Object.values(gameStatuses)
              .filter((s) => s.guessesToSolve != null)
              .map((s) => s.id - 1)
          ) + 1
        );

        setGame(randomGame);
      } else {
        setGame(await gameState.getGame(len, id));
      }
    };

    setViewGame();
  }, [lenStr, idStr, len, id]);

  useEffect(() => {
    if (game != null) {
      gameState.saveGame(game);
      ReactGA.event({
        category: 'game',
        action: 'game-open',
        label: `${game.length}-${game.id}`,
      });
    }
  }, [game]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentGuessInvalid(false);
    }, 500);
  }, [currentGuessInvalid]);

  if (!game) {
    return <LoadingScreen />;
  }

  if (idStr === 'random') {
    return <Navigate to={`/game/${lenStr}/${game.id}`} replace={true} />;
  }

  const onType = (c: string) => {
    if (currentGuess.length < len) {
      setCurrentGuess(currentGuess + c);
    }
  };

  const onBackspace = () => {
    if (currentGuess.length > 0) {
      setCurrentGuess(currentGuess.substring(0, currentGuess.length - 1));
    }
  };

  const onEnter = () => {
    if (currentGuess.length === len) {
      checkGuessValid(currentGuess, game.guesses, len).then((isValid) => {
        if (isValid) {
          setGame(
            new Dle({
              id: game.id,
              length: game.length,
              solution: game.solution,
              guesses: game.guesses.concat([currentGuess]),
            })
          );

          setCurrentGuess('');
        } else {
          setCurrentGuessInvalid(true);
        }
      });
    } else {
      setCurrentGuessInvalid(true);
    }
  };

  return (
    <div className="game-view">
      <h2 className="game-view-title">
        {game.length}-dle #{game.id}
      </h2>
      <Guesses
        len={game.length}
        guesses={game.guesses}
        statuses={game.guessStatuses}
        currentGuess={currentGuess}
        currentGuessInvalid={currentGuessInvalid}
      />

      <Keyboard
        onType={onType}
        onBackspace={onBackspace}
        onEnter={onEnter}
        guesses={game.guesses}
        solution={game.solution}
      />
    </div>
  );
}

export default GameView;
